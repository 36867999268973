.dsm-tooltip-light {

    .tooltip-inner {
        background-color: white;
        color: #1C1C1C;
        max-width: 70%;
        text-align: left;
        font-size: 16px;
        padding: 16px;
        z-index: 10;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        opacity: 1;
    }



}

.bs-tooltip-bottom {
    .tooltip-arrow {
        &::before {
            border-bottom-color: white !important;
        }
    }
}

.bs-tooltip-top {
    .tooltip-arrow {
        &::before {
            border-top-color: white !important;
        }
    }
}

.bs-tooltip-end {
    .tooltip-arrow {
        &::before {
            border-right-color: white !important;
        }
    }
}

.bs-tooltip-start {
    .tooltip-arrow {
        &::before {
            border-left-color: white !important;
        }
    }
}

@media (max-width: 768px) {
    .tooltip-info-icon {
        display: none;
    }
}