.proofRequest {
    width: calc(100% - 250px);
    padding: 16px 32px;
    // background-color: white;

}

.cardStyles {
    width: 300px;
    height: 130px;
    // border-radius: 30px;
    background-color: white;

}


.box {
    border-radius: 5px;
    background-color: #D4D9E1;
}

.img-styles {
    height: 50px;
    width: 50px;
    // bottom: -10px;
}