@import url("./_dashboard.scss");
@import url("./_header.scss");
@import url("./_sidebar.scss");
@import url("./_requests.scss");
@import url("./common/_add-service.scss");

@import url("./common/_customtoaster.scss");
@import url("./_components.scss");
@import "~bootstrap/scss/bootstrap";
@import "react-loading-skeleton/dist/skeleton.css";
@import url("./common/widget.scss");
@import './common/responsive';


* {
    font-family: 'Poppins';
}

body {
    margin: 0px auto;
}

.background {
    background-color: #19253a;
}

.main-layout {
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 0px;
    margin: 0px auto;
    overflow: hidden;
    position: relative;

    .wizard-btn {
        position: absolute;
        right: 24px;
        bottom: 24px;
        background-color: #ffffff;
        border-radius: 50px;
        padding: 8px 12px;
        border: none;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        img {
            margin-right: 8px;
        }

        @include respond-to(md) {
            display: none;
        }
    }

    .sidebar-menu {
        min-width: 250px;
        transition: ease-out 0.2s;
    }

    .collapsed-menu {
        min-width: 60px;
        transition: ease-in 0.2s;

        .collapse-brand-logo {
            width: 80px;
            padding: 30px 8px;

            img {
                @include respond-to(md) {
                    width: 36px;
                }
            }

        }

    }

    .right-container {
        width: calc(100% - 60px);
        background-color: #EAEFF1;
        overflow: auto;
        padding: 16px 32px;

        @include respond-to(md) {
            width: 100%;
            padding: 12px;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .inner-container {
            box-sizing: border-box;
            border-radius: 8px;

            .claims-card-section {
                display: grid;
                grid-template-columns: auto auto auto;
                grid-gap: 32px;
                margin-bottom: 16px;

                @include respond-to(xl) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include respond-to(lg) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include respond-to(md) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 12px;
                    margin-bottom: 8px;
                }
            }

            .request-proof-card-section {
                display: grid;
                grid-template-columns: auto auto auto auto;
                grid-gap: 32px;
                margin-bottom: 16px;

                @include respond-to(xl) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include respond-to(lg) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include respond-to(md) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 12px;
                    margin-bottom: 8px;
                }
            }

            .all-claims-cards {
                display: grid;
                grid-template-columns: auto auto auto auto;
                grid-gap: 16px;

                @include respond-to(xl) {
                    grid-template-columns: repeat(4, 1fr);
                }

                @include respond-to(lg) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include respond-to(md) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 12px;
                    margin-bottom: 8px;
                }

                @include respond-to(sm) {
                    grid-template-columns: repeat(1, 1fr);
                    grid-gap: 12px;
                    margin-bottom: 8px;
                }

                .service-card {
                    p {
                        font-size: 14px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}