@import 'responsive';

.widget {
  .text-blue {
    color: #307baa82;
  }

  .text-orange {
    color: #ea5a0c82;
  }

  .text-green {
    color: #16a34a82;
  }

  .text-dangers {
    color: #DC354582;
  }

  .borders-b {
    margin-bottom: 20px;
    border-bottom: 5px solid #307baa82;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }

  .borders-b:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }

  .borders-o {
    margin-bottom: 20px;
    border-bottom: 5px solid #ea5a0c82;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }

  .borders-o:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }

  .borders-g {
    margin-bottom: 20px;
    border-bottom: 5px solid #16a34a82;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }

  .borders-g:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }

  .borders-d {
    margin-bottom: 20px;
    border-bottom: 5px solid #DC354582;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }

  .borders-g:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }
}

.bigwidget {
  .bg-dark-blue {
    background-color: #313447;
    color: #fff;
  }

  .text-blue {
    color: #307baa82;
  }

  .text-light-blue {
    color: #4fc3f7;
  }

  .text-orange {
    color: #ea5a0c82;
  }

  .text-green {
    color: #16a34a82;
  }

  .borders-b {
    margin-bottom: 20px;
    border-bottom: 5px solid #4fc3f7;
    border-radius: 8px;

    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }

  .borders-o {
    margin-bottom: 20px;
    border-bottom: 5px solid #ea5a0c82;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }

  .borders-g {
    margin-bottom: 20px;
    border-bottom: 5px solid #16a34a82;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
  }
}

.borders-b {
  margin-bottom: 20px;
  border-bottom: 5px solid #4fc3f7;
  border-radius: 8px;

  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
}

@include respond-to(md) {
  .widget {

    .card {
      margin-bottom: 0px;
      min-height: 125px;

      .card-body {
        padding: 16px 8px;

        .card-subtitle {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .bigwidget {
    .card {


      .card-body {
        font-size: 14px;

        .card-subtitle {
          font-size: 16px;
          font-weight: 600;
        }

        hr {
          margin: 8px 0px !important;
        }
      }
    }
  }
}