@import './common/responsive';

.inner-body-container {
  box-sizing: border-box;
  height: 100%;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;

  .header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 16px;

    .search-bar-box {
      width: 300px;

      .search-bar {
        box-sizing: border-box;
        border: 1px solid #E2E3E5;
        border-radius: 4px;
        font-size: 16px;
        background-repeat: no-repeat;
        background-position: 0;
        background-position-x: 8px;
        background-size: 16px 16px;
        font-weight: 400;
        background-image: url("../images/search.svg");
        padding: 5px 5px 5px 35px;
        transition: width 0.4s ease-in-out;
        margin-bottom: 0;
        width: 100%;
        height: 38px;
        color: #777777;

        &:focus {
          transition: 0.2s ease-in;
          outline-color: #E2E3E5;
          border-color: #E2E3E5;
        }
      }

      [type="search"] {
        outline-color: gray;
        outline-offset: 0px;
      }
    }
  }

  .body {
    height: 530px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 12px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .view-fields {
      background: #f8f9fa;
      padding: 12px 16px;
      border-radius: 4px;
      margin-bottom: 16px;
    }

    .requests-table {
      width: 100%;
      border-collapse: collapse;
      color: #343330;
      font-size: 14px;
      font-weight: 500;

      thead {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: #EAEFF1;
        z-index: 2;

        tr {
          th {
            font-size: 16px;
            padding: 12px 0px;
            background-color: #eaeff175;
            text-align: left;
            padding-left: 24px;
            color: #1C1C1C;

            &:nth-child(3) {
              text-align: center;
            }

            &:nth-child(4) {
              text-align: center;
            }

            &:nth-child(5) {
              text-align: center;
            }
          }
        }
      }

      tbody {
        tr {

          border-bottom: 1px solid #e7e7ed;

          td {
            padding: 12px 0px;
            text-align: left;
            padding-left: 24px;
            color: #777777;
            font-size: 16px;

            .copy-icon {
              cursor: pointer;
              position: relative;
              right: 8px;
            }

            &:nth-child(3) {
              text-align: center;
            }

            &:nth-child(4) {
              text-align: center;
            }

            &:nth-child(5) {
              text-align: center;
            }
          }
        }
      }

      .empty-screen-tr {
        border-bottom: none;
        position: relative;
        top: 80px;
      }
    }

    .available-services-container {
      height: 65vh;
      overflow: auto;
    }
  }

  .inner-page-footer {
    display: flex;
    height: max-content;
    flex-direction: row-reverse;
    gap: 8px;
    padding: 12px 16px;
  }
}

.verifiable-container {
  background-color: transparent;
  padding: 8px 4px;


  .header {
    background-color: transparent;

    .sub-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }

  .body {
    height: auto;

    .sub-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0px;
    }

  }
}

@include respond-to(md) {
  .inner-body-container {
    .body {
      overflow: auto;

      .requests-table {
        width: max-content;

        thead {
          tr {
            th {
              font-size: 12px;
            }
          }
        }

        tbody {
          tr {

            td {
              font-size: 10px;

              .connection-did {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .available-services-container {
        height: 70vh;
        overflow: auto;
        padding: 12px 8px;
      }
    }

    .profile-seciton {
      height: -moz-fit-content;
      height: fit-content;
      overflow-x: hidden;
    }
  }

  .verifiable-container {
    .header {
      flex-direction: row-reverse;

      .sub-title {
        margin-bottom: 16px;
        display: none;
      }
    }
  }
}