@mixin commonScroll {

    /* width */
    &::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #ffffff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #f1f1f1;
        border-radius: 12px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #f1f1f1;
    }
}

// service information
.service-information {
    padding: 12px 16px;
    background-color: #ffffff;
    height: calc(100vh - 25em);
    overflow: auto;
    @include commonScroll;
}

// connect data soruce

.connect-data-source {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
    padding: 12px 16px;
    background-color: #ffffff;
    height: calc(100vh - 330px);
    overflow: auto;
    @include commonScroll;

    .chose-title {
        h2 {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #272833;
            margin-bottom: 0px;
        }
    }

    .connect-data-source-list {
        width: 100%;

        ul {
            list-style: none;
            padding: 0px;
            margin: 0px;

            .data-source-active {
                border: 1px solid #4398CD;
                background-color: #e5f1ff;

                &:hover {
                    border: 1px solid #4398CD;
                }
            }

            li {
                position: relative;
                border: 1.5px solid #CDCED9;
                padding: 12px;
                width: 100%;
                border-radius: 4px;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                &:hover {
                    background-color: #e5f1ff;
                    border: 1px solid #e5f1ff;
                }

                p {
                    font-size: 14px;
                    color: #272833;
                    font-weight: 600;
                    margin-bottom: 0px;
                    display: flex;
                    align-items: center;
                }

                small {
                    font-size: 12px;
                    font-weight: 500;
                    color: #6B6C7E;
                    padding-left: 26px;
                }
            }

            .selected-item {
                border: 1px solid #4398CD;
                background-color: #e5f1ff;
            }
        }
    }
}

// service information
.dat-source-information {
    padding: 12px 16px;
    background-color: #ffffff;
    height: calc(100vh - 330px);
    overflow: auto;
    @include commonScroll;

    .chose-title {
        h2 {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #272833;
            margin-bottom: 0px;
        }
    }
}