/* Style for the modal header */
.dsm-modal-view .modal-header {
    border-bottom: none;
  }

  .dsm-modal-view .modal-footer {
    border-top: none;
  }
  
  .dsm-modal-view .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
  }  
  .dsm-modal-view .modal-header .btn-close{
    font-size: 13px;
    box-shadow: none;
  }
  
  /* Style for the modal body */
  .dsm-modal-view .modal-body {
    padding: 20px;
  }
  
  /* Style for the content container */
  .modal-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  /* Style for the data list */
  .data-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }
  
  /* Style for each data item */
  .data-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  
  .data-item .data-field {
    word-break: break-word;
  }
  
  .data-item .data-date:hover {
    overflow: visible;
    white-space: normal;

    z-index: 1;
    position: relative;
  }
  
  /* Style for hover effect on data date */
  .data-item .data-date:hover::after {
    content: attr(title);
    position: absolute;
    left: 0;
    top: 100%;
    white-space: nowrap;
    background: #fff;
    /* border: 1px solid #ddd; */
    padding: 5px;
    z-index: 2;
    width: max-content;
    max-width: 100%;
    transition: opacity 0.3s ease-in-out 0.3s; 
}

.data-item .data-date:hover::after {
  opacity: 1;
}
  

@media
(max-width: 768px) { .dsm-modal-view .modal-body {
    max-height: 50vh;
    overflow: auto;
} }